import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SendolaBlackWhiteLogo from "@/assets/Img/sendola-black-white-logo.png";
import SendolaBlackLogo from "@/assets/Img/sendola-black-logo.png";
import SendolaBlackMockup from "@/assets/Img/sendola-black-mockup.png";
import DesktopYacht from "@/assets/Img/desktop-yatch.png";
import ExteriorHome from "@/assets/Img/exterior-home.jpg";
import SkiMountain from "@/assets/Img/ski-mountain.jpg";
import HomeElDorado from "@/assets/Img/home-el-dorado.jpg";
import AssetsBankGrade from "@/assets/Img/assets-bank-grade.png";
import GlobalReact from "@/assets/Img/global-reach.png";
import CostEffective from "@/assets/Img/cost-effective.png";
import DedicatedSupport from "@/assets/Img/dedicated-support.png";
import EmailSupport from "@/assets/Img/email-support.png";
import PhoneSupport from "@/assets/Img/phone-support.png";
import LiveChat from "@/assets/Img/live-chat.png";
import TraditionalBankingGraphic from "@/assets/Img/traditional-banking-graphic.png";
import OctGraphic1 from "@/assets/Img/oct-graphic-1.png";
import OctGraphic2 from "@/assets/Img/oct-graphic-2.png";
import UserGoldIcon from "@/assets/Img/user-gold.png";
import ConnectIcon from "@/assets/Img/connect.png";
import InternationalIcon from "@/assets/Img/international.png";
import TransactionBox1 from "@/assets/Img/transaction-box-1.png";
import TransactionBox2 from "@/assets/Img/transaction-box-2.png";
import TransactionBox3 from "@/assets/Img/transaction-box-3.png";
import TransactionBox4 from "@/assets/Img/transaction-box-4.png";
import TransactionBox4Mobile from "@/assets/Img/transaction-box-4-mobile.png";
import BankDetails from "@/assets/Img/banks-details.png";
import QuoteIcon from "@/assets/Icons/quotes.svg?react";
import ExternalLink from "@/assets/Icons/external-link.svg?react";
import { QuestionItem } from "@/components/QuestionItem";
import { Card } from "@/components/Card";
import { Modal } from "@/components/Modal";
import HubspotContactForm from "@/components/HubspotContactForm";
import classNames from "classnames";

export const SendolaBlackPage = () => {
  const [t] = useTranslation("global");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isCostModalOpen, setIsCostModalOpen] = useState<boolean>(false);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const [isBannerSticky, setIsBannerSticky] = useState<boolean>(false);

  const bannerRef = useRef<HTMLDivElement>(null);

  const openModal = () => {
    setLoadingForm(true);
    setFormSubmitted(false);
    setIsModalOpen(true);
  };
  const openCostModal = () => {
    setIsCostModalOpen(true);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY > 600) setShowBanner(true);
      else setShowBanner(false);
    };

    if (bannerRef?.current) {
      const windowHeight = window.innerHeight;
      const banner = bannerRef?.current;
      const elementBottom = banner.getBoundingClientRect().bottom;

      const distanceToBottom = windowHeight - elementBottom;

      if (distanceToBottom > 0) {
        setIsBannerSticky(false);
      } else {
        setIsBannerSticky(true);
      }
    }

    document.addEventListener("scroll", handleScroll);

    return () => document.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className="w-full flex flex-col">
        <div className="relative w-full flex flex-col items-center h-fit gold-radial-gradient px-6 lg:px-10">
          <img
            className="w-60 md:w-80 lg:w-96 h-auto py-10 z-10"
            src={SendolaBlackWhiteLogo}
            alt="Sendola Black's logo"
          />
          <img
            className="w-full object-contain max-w-screen-md  -mt-[10vh] sm:-mt-[17vh] translate-y-[29.6%]"
            src={DesktopYacht}
            alt="Sendola Black's logo"
          />
        </div>

        <div className="bg-sendolab-gray flex justify-center px-6 lg:px-10 pb-20 pt-52 xs:pt-48 md:pt-60">
          <div className="w-full flex flex-col md:flex-row gap-8 rounded-3xl bg-sendolab-brown p-20 max-w-screen-xl">
            <div className="h-full flex items-center w-full md:w-1/2">
              <p className="font-optima text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-sendolab-gray">
                {t("SendolaBlack.Section1.Title")}
              </p>
            </div>
            <div className="flex flex-col justify-center gap-4 w-full md:w-1/2">
              <p
                className="text-sendolab-white font-light inner-b-semibold text-sm md:text-base lg:text-lg"
                dangerouslySetInnerHTML={{
                  __html: t("SendolaBlack.Section1.Paragraph1"),
                }}
              />
              <p
                className="text-sendolab-white font-light inner-b-semibold text-sm md:text-base lg:text-lg"
                dangerouslySetInnerHTML={{
                  __html: t("SendolaBlack.Section1.Paragraph2"),
                }}
              />
            </div>
          </div>
        </div>

        <div className="w-full bg-sendolab-brown h-44 flex gap-8 items-center justify-center px-6 lg:px-10">
          <img src={AssetsBankGrade} className="text-sendolab-gray h-16 w-16" />
          <p className="font-semibold text-sendolab-white text-base sm:text-lg md:text-xl lg:text-3xl">
            {t("SendolaBlack.Section2.Title")}
          </p>
        </div>

        <div className="flex flex-col gap-10 w-full py-10 px-6 lg:px-10 relative">
          <img
            src={ExteriorHome}
            className="absolute left-0 top-0 w-full h-full object-cover object-top"
          />
          <div className="absolute left-0 top-0 w-full h-full bg-gradient-to-b from-white/20 to-55% to-black/30" />

          <div className="w-full z-10">
            <h1 className="text-center text-4xl lg:text-5xl 2xl:text-6xl mt-10 text-sendolab-brown font-optima">
              {t("SendolaBlack.Section3.Title")}
            </h1>
            <p className="text-center text-2xl lg:text-3xl 2xl:text-4xl text-sendolab-brown mt-6 font-optima">
              {t("SendolaBlack.Section3.Subtitle")}
            </p>
          </div>

          <div className="w-full inline-flex flex-wrap items-center justify-center gap-8 z-10 mt-20">
            <Card
              rounded="rounded-3xl"
              className="flex flex-col gap-4 w-72 lg:w-80 items-center p-6 shadow-none pb-20"
            >
              <img className="object-contain w-16 h-16" src={CostEffective} />
              <p className="text-sendolab-brown font-light text-lg lg:text-xl text-center h-6 sm:h-12 md:h-8">
                {t("SendolaBlack.Section3.Card1.Title")}
              </p>
              <p
                className="text-sendolab-gray"
                dangerouslySetInnerHTML={{
                  __html: t("SendolaBlack.Section3.Card1.Paragraph1"),
                }}
              />
              <p
                className="text-sendolab-gray"
                dangerouslySetInnerHTML={{
                  __html: t("SendolaBlack.Section3.Card1.Paragraph2"),
                }}
              />
            </Card>
            <Card
              rounded="rounded-3xl"
              className="flex flex-col gap-4 w-72 lg:w-80 items-center p-6 shadow-none pb-20"
            >
              <img className="object-contain w-16 h-16" src={GlobalReact} />
              <p className="text-sendolab-brown font-light text-lg lg:text-xl text-center h-6 sm:h-12 md:h-8">
                {t("SendolaBlack.Section3.Card2.Title")}
              </p>
              <p
                className="text-sendolab-gray"
                dangerouslySetInnerHTML={{
                  __html: t("SendolaBlack.Section3.Card2.Paragraph1"),
                }}
              />
            </Card>
            <Card
              rounded="rounded-3xl"
              className="flex flex-col gap-4 w-72 lg:w-80 items-center p-6 shadow-none pb-20"
            >
              <img
                className="object-contain w-16 h-16"
                src={DedicatedSupport}
              />
              <p className="text-sendolab-brown font-light text-lg lg:text-xl text-center h-6 sm:h-12 md:h-8">
                {t("SendolaBlack.Section3.Card3.Title")}
              </p>
              <p
                className="text-sendolab-gray"
                dangerouslySetInnerHTML={{
                  __html: t("SendolaBlack.Section3.Card3.Paragraph1"),
                }}
              />
            </Card>
          </div>

          <div className="w-full h-44 flex gap-4 items-center justify-center z-10">
            <QuoteIcon className="text-sendolab-white h-8 w-8 sm:h-16 sm:w-16 md:h-20 md:w-20 flex-shrink-0" />
            <p className="text-white text-base sm:text-lg md:text-xl lg:text-2xl font-optima text-center">
              {t("SendolaBlack.Section4.Title")}
            </p>
            <QuoteIcon className="text-sendolab-white h-8 w-8 sm:h-16 sm:w-16 md:h-20 md:w-20 flex-shrink-0 rotate-180" />
          </div>
        </div>

        <div className="w-full flex flex-col justify-center items-center gap-6 bg-black py-16 px-6 lg:px-10">
          <div className="w-full">
            <h1 className="text-center w-full text-4xl lg:text-5xl 2xl:text-6xl text-sendolab-white font-optima mb-4">
              {t("SendolaBlack.Section8.Title")}
            </h1>
            <p className="lg:text-lg text-center w-full text-sendolab-gray">
              {t("SendolaBlack.Section8.Subtitle")}
            </p>
          </div>

          <div className="w-fit">
            <div className="w-full flex flex-col md:flex-row">
              <div className="w-full md:w-1/3">
                <img
                  className="w-full max-h-[38rem] object-contain -mr-2"
                  src={TraditionalBankingGraphic}
                  alt="Traditional banking table"
                />
                <button
                  onClick={openCostModal}
                  className="w-full bg-transparent text-center text-xs lg:text-sm text-sendolab-gray underline h-0 -translate-y-20 md:-translate-y-12 lg:-translate-y-20"
                >
                  {t("SendolaBlack.Section8.OtherBanks")}
                </button>
              </div>
              <img
                className="w-full md:w-2/3 max-h-[38rem] object-contain -ml-2 max-md:-mt-10 max-md:max-h-[28rem]"
                src={OctGraphic1}
                alt="Cost breakdown table 1"
              />
            </div>

            <p className="w-full text-center text-base md:text-lg lg:text-xl text-sendolab-gray mt-6">
              {t("SendolaBlack.Section8.SavingsTitle")}
            </p>
            <img
              className="w-full max-h-[24rem] object-contain px-4"
              src={OctGraphic2}
              alt="Cost breakdown table 2"
            />
          </div>

          <div className="w-full flex flex-col gap-2 md:max-w-[80%]">
            <p className="w-full text-center text-xs md:text-sm text-sendolab-gray font-light">
              {t("SendolaBlack.Section8.Disclaimer1")}
            </p>
            <p className="w-full text-center text-xs md:text-sm text-sendolab-gray font-light">
              {t("SendolaBlack.Section8.Disclaimer2")}
            </p>
          </div>
        </div>

        <div className="w-full relative h-fit">
          <img
            className="w-full object-cover object-center min-h-[24rem] max-h-[48rem]"
            src={SkiMountain}
            alt="People skiing"
          />
          <div className="absolute flex items-center justify-center left-0 top-0 w-full h-full bg-black/35 px-6 lg:px-10">
            <p className="text-xl md:text-3xl lg:text-4xl font-optima text-white text-center max-w-screen-xl">
              {t("SendolaBlack.Section6.Quote")}
            </p>
          </div>
        </div>

        <div className="w-full flex justify-center gold-radial-gradient py-16 md:py-28 px-6 lg:px-10">
          <div className="w-full flex justify-around gap-6 md:gap-10 max-w-screen-xl">
            <div className="flex flex-col gap-8">
              <p className="font-optima text-sendolab-gold2 text-3xl md:text-4xl lg:text-5xl">
                {t("SendolaBlack.Section6.Title1")}
              </p>
              <p
                className="text-sm lg:text-base text-sendolab-white"
                dangerouslySetInnerHTML={{
                  __html: t("SendolaBlack.Section6.Paragraph1"),
                }}
              />
              <p
                className="text-sm lg:text-base text-sendolab-white"
                dangerouslySetInnerHTML={{
                  __html: t("SendolaBlack.Section6.Paragraph2"),
                }}
              />
              <p className="font-optima text-sendolab-gold2 text-3xl md:text-4xl lg:text-5xl">
                {t("SendolaBlack.Section6.Title2")}
              </p>
              <p
                className="text-sm lg:text-base text-sendolab-white"
                dangerouslySetInnerHTML={{
                  __html: t("SendolaBlack.Section6.Paragraph3"),
                }}
              />
              <p
                className="text-sm lg:text-base text-sendolab-white"
                dangerouslySetInnerHTML={{
                  __html: t("SendolaBlack.Section6.Paragraph4"),
                }}
              />

              <button
                onClick={openModal}
                className="rounded-full px-10 py-2 bg-sendolab-gold2 text-sendolab-white w-fit hover:opacity-90"
              >
                {t("SendolaBlack.Section6.Button")}
              </button>
            </div>
            <img
              className="object-contain w-60 md:w-96 -my-8 max-sm:hidden"
              src={SendolaBlackMockup}
            />
          </div>
        </div>

        <div className="w-full bg-sendolab-gray px-6 py-20 flex justify-center">
          <div className="w-full flex flex-col gap-4 items-center justify-center max-w-screen-xl">
            <h1
              className="text-center text-4xl lg:text-5xl 2xl:text-6xl text-sendolab-gray2 font-optima font-semibold mb-10"
              dangerouslySetInnerHTML={{
                __html: t("SendolaBlack.Section7.Title"),
              }}
            />

            <div className="w-full rounded-3xl bg-white flex items-center min-h-32">
              <p className="text-5xl md:text-7xl font-serif text-sendolab-gold2/45 w-16 md:w-28 text-center h-16 md:h-24">
                1
              </p>
              <div className="w-full h-full rounded-3xl flex items-center gap-6 bg-sendolab-brown text-sendolab-white px-6 py-2">
                <img
                  src={UserGoldIcon}
                  className="w-12 h-12 flex-shrink-0 max-sm:hidden"
                />
                <div className="h-full py-2 flex flex-col gap-2 justify-center">
                  <p className="font-semibold text-base md:text-xl">
                    {t("SendolaBlack.Section7.Card1.Title")}
                  </p>
                  <p className="font-light text-xs md:text-sm lg:text-base">
                    {t("SendolaBlack.Section7.Card1.Content")}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full rounded-3xl bg-white flex items-center min-h-32">
              <p className="text-5xl md:text-7xl font-serif text-sendolab-gold2/45 w-16 md:w-28 text-center h-16 md:h-24">
                2
              </p>
              <div className="w-full h-full rounded-3xl flex items-center gap-6 bg-sendolab-brown text-sendolab-white px-6 py-2">
                <img
                  src={ConnectIcon}
                  className="w-12 h-12 flex-shrink-0 max-sm:hidden"
                />
                <div className="h-full py-2 flex flex-col gap-2 justify-center">
                  <p className="font-semibold text-base md:text-xl">
                    {t("SendolaBlack.Section7.Card2.Title")}
                  </p>
                  <p className="font-light text-xs md:text-sm lg:text-base">
                    {t("SendolaBlack.Section7.Card2.Content")}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full rounded-3xl bg-white flex items-center min-h-32">
              <p className="text-5xl md:text-7xl font-serif text-sendolab-gold2/45 w-16 md:w-28 text-center h-16 md:h-24">
                3
              </p>
              <div className="w-full h-full rounded-3xl flex items-center gap-6 bg-sendolab-brown text-sendolab-white px-6 py-2">
                <img
                  src={InternationalIcon}
                  className="w-12 h-12 flex-shrink-0 max-sm:hidden"
                />
                <div className="h-full py-2 flex flex-col gap-2 justify-center">
                  <p className="font-semibold text-base md:text-xl">
                    {t("SendolaBlack.Section7.Card3.Title")}
                  </p>
                  <p className="font-light text-xs md:text-sm lg:text-base">
                    {t("SendolaBlack.Section7.Card3.Content")}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-center mt-6">
              <button
                onClick={openModal}
                className="w-fit px-10 py-3 rounded-full text-sendolab-white bg-sendolab-gray2 hover:opacity-80"
              >
                {t("SendolaBlack.Section7.Button")}
              </button>
            </div>
          </div>
        </div>

        <div className="w-full relative h-fit">
          <img
            className="w-full object-cover object-bottom min-h-[24rem] max-h-[48rem]"
            src={HomeElDorado}
            alt="A House on the beach"
          />
          <div className="absolute flex items-center justify-center left-0 top-0 w-full h-full bg-black/55 px-6 lg:px-10">
            <p className="text-xl md:text-3xl lg:text-4xl font-optima text-white text-center max-w-screen-xl">
              {t("SendolaBlack.Section9.Subtitle")}
            </p>
          </div>
        </div>

        <div className="w-full bg-sendolab-gray px-6 py-20 flex flex-col items-center">
          <div className="w-full flex flex-col gap-4 items-center justify-center max-w-screen-xl">
            <h1 className="text-center text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl lg:max-w-[50%] text-white font-optima">
              {t("SendolaBlack.Section9.Title")}
            </h1>

            <div className="w-full flex flex-col items-center rounded-3xl p-4 mt-10">
              <div className="flex justify-center items-center flex-col md:flex-row">
                <img
                  className="w-[120%] xs:max-w-96 md:w-1/3 h-auto object-contain -mt-12 md:-m-4"
                  src={TransactionBox1}
                />
                <img
                  className="w-[120%] xs:max-w-96 md:w-1/3 h-auto object-contain -mt-12 md:-m-4"
                  src={TransactionBox2}
                />
                <img
                  className="w-[120%] xs:max-w-96 md:w-1/3 h-auto object-contain -mt-12 md:-m-4 md:-mb-10"
                  src={TransactionBox3}
                />
              </div>
              <img
                className="w-[90%] xl:w-[80%] h-auto max-md:-mt-8 hidden md:block"
                src={TransactionBox4}
              />
              <img
                className="w-full max-w-[22rem] h-auto max-md:-mt-8 md:hidden"
                src={TransactionBox4Mobile}
              />
              <p
                className="text-center text-sm lg:text-base text-white font-light -mt-2"
                dangerouslySetInnerHTML={{
                  __html: t("SendolaBlack.Section9.Disclaimer1"),
                }}
              />
            </div>
          </div>
        </div>

        <div className="w-full bg-sendolab-white flex flex-col gap-4 px-6 py-20 items-center justify-center">
          <h1 className="text-center text-4xl lg:text-5xl 2xl:text-6xl text-sendolab-brown font-optima font-semibold mb-10">
            {t("SendolaBlack.Section5.Title")}
          </h1>

          <p className="font-light text-lg sm:text-xl md:text-2xl lg:text-3xl text-center">
            {t("SendolaBlack.Section5.Subtitle")}
          </p>
          <div className="w-full max-sm:flex-col max-sm:gap-2 items-center flex justify-center gap-10">
            <div className="flex gap-2">
              <img className="w-6 h-6" src={EmailSupport} />
              <p>{t("SendolaBlack.Section5.Method1")}</p>
            </div>
            <div className="flex gap-2">
              <img className="w-6 h-6" src={PhoneSupport} />
              <p>{t("SendolaBlack.Section5.Method2")}</p>
            </div>
            <div className="flex gap-2">
              <img className="w-6 h-6" src={LiveChat} />
              <p>{t("SendolaBlack.Section5.Method3")}</p>
            </div>
          </div>
        </div>

        <div
          ref={bannerRef}
          className={classNames(
            "w-full flex shadow-t-lg bottom-0 z-20 transition-all delay-100 duration-200 ease-linear",
            {
              sticky: showBanner,
              "h-20": showBanner || isBannerSticky,
              "block h-0 overflow-hidden": !showBanner,
            }
          )}
        >
          <div
            className={classNames(
              "w-8/12 h-full bg-black flex items-center transition-all duration-400 pt-1",
              {
                "max-h-0": !showBanner,
                "max-h-24": showBanner,
              }
            )}
          >
            <p className="w-full md:text-xl lg:text-2xl text-center px-6 underline underline-offset-8 text-sendolab-white font-extralight">
              {t("SendolaBlack.Section10.ApplyTitle")}
            </p>
          </div>
          <div
            className={classNames(
              "w-4/12 h-full bg-sendolab-gray flex items-center justify-center px-4 transition-all duration-400 pt-1",
              {
                "max-h-0": !showBanner,
                "max-h-24": showBanner,
              }
            )}
          >
            <button
              onClick={openModal}
              className="border-[1px] border-sendolab-brown font-optima px-4 md:px-12 py-2 text-sm md:text-lg lg:text-xl"
            >
              {t("SendolaBlack.Section10.ApplyButton")}
            </button>
          </div>
        </div>

        <div className="w-full gold-radial-gradient py-20 px-10 md:px-20 flex justify-center">
          <div className="w-full flex flex-col justify-center items-center gap-6 max-w-screen-xl">
            <h1 className="text-center text-4xl lg:text-5xl 2xl:text-6xl text-white lg:max-w-[60%] font-optima mb-4">
              {t("SendolaBlack.Section10.Title")}
            </h1>

            <QuestionItem
              color="white"
              question={t("SendolaBlack.Section10.Question1.Question")}
              answer={t("SendolaBlack.Section10.Question1.Answer")}
            />
            <QuestionItem
              color="white"
              question={t("SendolaBlack.Section10.Question2.Question")}
              answer={t("SendolaBlack.Section10.Question2.Answer")}
            >
              <ul className="list-disc list-inside flex flex-col gap-1 mt-4 text-xs md:text-sm">
                <li className="">
                  {t("SendolaBlack.Section10.Question2.Bullet1")}
                </li>
                <li className="">
                  {t("SendolaBlack.Section10.Question2.Bullet2")}
                </li>
              </ul>

              <p
                className="font-semibold mt-4 text-sm md:text-base"
                dangerouslySetInnerHTML={{
                  __html: t("SendolaBlack.Section10.Question2.Details"),
                }}
              ></p>
            </QuestionItem>
            <QuestionItem
              color="white"
              question={t("SendolaBlack.Section10.Question3.Question")}
            >
              <a
                href="https://s3.us-west-1.amazonaws.com/sendola.io/Black/Payment+Methods+V2.pdf"
                target="_black"
                rel="noopener noreferrer"
                className="flex items-center gap-1 underline underline-offset-4 font-light cursor-pointer"
              >
                {t("SendolaBlack.Section10.Question3.Answer")}
                <ExternalLink className="w-5 h-5" />
              </a>
            </QuestionItem>
            <QuestionItem
              color="white"
              question={t("SendolaBlack.Section10.Question4.Question")}
              answer={t("SendolaBlack.Section10.Question4.Answer")}
            />
            <QuestionItem
              color="white"
              question={t("SendolaBlack.Section10.Question5.Question")}
              answer={t("SendolaBlack.Section10.Question5.Answer")}
            />
          </div>
        </div>
      </div>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="w-full flex flex-col items-center">
          {!formSubmitted && (
            <img
              className="w-48 md:w-80 mb-10"
              src={SendolaBlackLogo}
              alt="Sendola black's logo"
            />
          )}
          {loadingForm && (
            <p className="w-full text-center">{t("Loading")}...</p>
          )}
          <HubspotContactForm
            region="na1"
            portalId="44207752"
            formId="8338283d-2d9c-43c1-ad9d-dedd8617ea5c"
            onReady={() => {
              setLoadingForm(false);
            }}
            onSubmit={() => {
              setFormSubmitted(true);
            }}
          />
        </div>
      </Modal>

      <Modal
        open={isCostModalOpen}
        withClose
        onClose={() => setIsCostModalOpen(false)}
      >
        <div className="w-full flex flex-col items-center">
          <img
            className="w-full object-contain -mt-[10%]"
            src={BankDetails}
            alt="Cost table for other banks 2"
          />
        </div>
      </Modal>
    </>
  );
};
